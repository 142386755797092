import React from 'react'
import styles from './footer.module.scss'
import Link from '../link'

const Footer = () => (
  <footer className={styles.el}>
    Images are copyrighted by their respective copyright owners.{' '}
    <Link to="/disclaimer/">Disclaimer</Link>.
  </footer>
)

export default Footer
