import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import styles from './index.module.scss'
import Thumb from '../../components/thumb'
import Grid from '../../components/grid'
import Footer from '../../components/footer'
import FilterMenu from '../../components/filter-menu'
import PostLightbox from '../../components/post-lightbox'

const IndexTemplate = ({ data, pageContext }) => {
  const [postLightbox, setPostLightbox] = useState(null)
  const filterValue = get(pageContext, 'filter.value')
  const filterType = get(pageContext, 'filter.type')

  const thumbs = []
  data.allMarkdownRemark.edges.forEach((item, index) => {
    const { node } = item
    thumbs.push({
      index,
      title: get(node, 'frontmatter.title'),
      year: get(node, 'frontmatter.year'),
      path: get(node, 'fields.path'),
      thumbHover: get(node, 'frontmatter.thumb.publicURL'),
      thumb: get(node, 'frontmatter.thumb_blue.publicURL'),
      taxonomy: get(node, 'frontmatter.taxonomy'),
      link: get(node, 'frontmatter.link'),
      image:
        get(node, 'frontmatter.animated_image.publicURL') ||
        get(node, 'frontmatter.static_image.publicURL'),
    })
  })
  const handleClickThumb = post => {
    setPostLightbox(post)
    window.history.pushState(null, null, post.path)
  }

  const handleCloseLightbox = () => {
    window.history.pushState(null, null, pageContext.route)
    setPostLightbox(null)
  }

  const handleNext = () => {
    let nextIndex = postLightbox.index + 1
    if (nextIndex >= thumbs.length) {
      nextIndex = 0
    }
    setPostLightbox(thumbs[nextIndex])
  }

  const handlePrev = () => {
    let prevIndex = postLightbox.index - 1
    if (prevIndex < 0) {
      prevIndex = thumbs.length - 1
    }
    setPostLightbox(thumbs[prevIndex])
  }

  return (
    <>
      <PostLightbox
        post={postLightbox}
        onClose={handleCloseLightbox}
        onNext={handleNext}
        onPrev={handlePrev}
      />
      <FilterMenu activeFilter={filterValue} />
      <Grid fullHeight>
        <div className={styles.el__intro}>
          <h1 className={styles.el__intro__logo}>
            <a href="/" className={styles.el__intro__logo__link}>
              Title Scream
            </a>
          </h1>
          <div className={styles.el__intro__description}>
            <div className={styles.el__intro__description__desktop}>
              Type + Graphic inspiration from 8/16bit games
            </div>
            <div className={styles.el__intro__description__mobile}>
              Graphics from 80-90s Video Games
            </div>
          </div>
        </div>
        {thumbs.map(item => {
          const itemFilters = filterType
            ? item.taxonomy[filterType.toLowerCase()]
            : []

          if (
            filterValue &&
            itemFilters &&
            !itemFilters.includes(filterValue)
          ) {
            return undefined
          }
          return (
            <Thumb
              key={item.path}
              onClick={() => {
                handleClickThumb(item)
              }}
              {...item}
            />
          )
        })}
      </Grid>
      <Footer />
    </>
  )
}

IndexTemplate.defaultProps = {
  data: {},
  pageContext: {},
}

IndexTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query Posts {
    allMarkdownRemark {
      edges {
        node {
          fields {
            path
          }
          frontmatter {
            title
            year
            link
            thumb {
              publicURL
            }
            thumb_blue {
              publicURL
            }
            animated_image {
              publicURL
            }
            static_image {
              publicURL
            }
            taxonomy {
              publisher
              developer
              platform
              tag
            }
          }
        }
      }
    }
  }
`

export default IndexTemplate
