import React from 'react'
import PropTypes from 'prop-types'
import Link from '../link'
import styles from './filter-dropdown.module.scss'

const FilterDropdown = ({ label, list }) => (
  <div className={styles.el}>
    <div className={styles.el__label}>{label}</div>
    <ul className={styles.el__list}>
      {list.map(item => (
        <li className={styles.el__item} key={item.label}>
          <Link className={styles.el__link} to={item.url}>
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  </div>
)

FilterDropdown.defaultProps = {
  label: undefined,
  list: [],
}

FilterDropdown.propTypes = {
  label: PropTypes.string,
  list: PropTypes.array,
}

export default FilterDropdown
