import React from 'react'
import PropTypes from 'prop-types'
import PostDetail from '../post-detail'
import styles from './post-lightbox.module.scss'

const PostLightbox = ({ post, onClose, onNext, onPrev }) => {
  const handleNext = () => {
    if (typeof onNext === 'function') {
      onNext()
    }
  }

  const handlePrev = () => {
    if (typeof onPrev === 'function') {
      onPrev()
    }
  }

  return (
    <div className={styles.el} data-active={post !== null}>
      <button
        type="button"
        className={styles.el__bg}
        onClick={onClose}
        aria-label="Close"
      />
      <div className={styles.el__container}>
        <nav className={styles.el__nav}>
          {onPrev && (
            <button
              type="button"
              onClick={handlePrev}
              className={styles.el__nav__btn}
              data-direction="prev"
            >
              Prev
            </button>
          )}
          {onNext && (
            <button
              type="button"
              onClick={handleNext}
              className={styles.el__nav__btn}
              data-direction="next"
            >
              Next
            </button>
          )}
        </nav>
        {post && (
          <PostDetail
            title={post.title}
            taxonomy={post.taxonomy}
            image={post.image}
            link={post.link}
            year={post.year}
            notes={post.html}
          />
        )}
      </div>
    </div>
  )
}

PostLightbox.defaultProps = {
  post: null,
  originalPath: '',
  onClose: () => {},
  onNext: undefined,
  onPrev: undefined,
}

PostLightbox.propTypes = {
  post: PropTypes.object,
  originalPath: PropTypes.string,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
}

export default PostLightbox
