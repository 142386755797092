import React from 'react'
import PropTypes from 'prop-types'
import Link from '../link'
import FilterDropdown from '../filter-dropdown'
import styles from './filter-menu.module.scss'

const FilterMenu = ({ activeFilter }) => (
  <div className={styles.el} data-active={!!activeFilter}>
    {activeFilter ? (
      <>
        <div className={styles.el__part}>
          <div className={styles.el__label}>Filtered by: </div>
        </div>
        <div className={styles.el__part}>
          <div className={styles.el__label}>{activeFilter}</div>
        </div>
        <Link to="/" className={styles.el__button}>
          Clear Filters
        </Link>
      </>
    ) : (
      <>
        <div className={styles.el__part}>
          <div className={styles.el__label}>Filter by: </div>
        </div>
        <div className={styles.el__part}>
          <FilterDropdown
            label="Platform"
            list={[
              { label: 'NES', url: '/platform/nes' },
              { label: 'SNES', url: '/platform/snes' },
              { label: 'Sega Genesis', url: '/platform/sega-genesis' },
              {
                label: 'Sega Master System',
                url: '/platform/sega-master-system',
              },
              { label: 'ZX Spectrum', url: '/platform/zx-spectrum' },
              { label: 'Atari 5200', url: '/platform/atari-5200' },
              { label: 'Amiga', url: '/platform/amiga' },
              { label: 'BBC Micro', url: '/platform/bbc-micro' },
            ]}
          />
        </div>
        <div className={styles.el__part}>
          <FilterDropdown
            label="Publisher"
            list={[
              { label: 'Sega', url: '/publisher/sega' },
              { label: 'Capcom', url: '/publisher/capcom' },
              { label: 'Nintendo', url: '/publisher/nintendo' },
              { label: 'Konami', url: '/publisher/konami' },
              { label: 'Data East', url: '/publisher/data-east' },
              { label: 'Taito', url: '/publisher/taito' },
              { label: 'Bandai', url: '/publisher/bandai' },
              { label: 'Acclaim', url: '/publisher/acclaim' },
              { label: 'Jaleco', url: '/publisher/jaleco' },
              { label: 'Hudson Soft', url: '/publisher/hudson-soft' },
              { label: 'LJN', url: '/publisher/ljn' },
              { label: 'Namco', url: '/publisher/namco' },
            ]}
          />
        </div>
        <div className={styles.el__part}>
          <FilterDropdown
            label="Tag"
            list={[
              { label: 'Animated', url: '/tag/animated' },
              { label: 'Platform', url: '/tag/platform' },
              { label: 'Licensed', url: '/tag/licensed' },
              { label: 'Arcade', url: '/tag/arcade' },
              { label: 'Fighting', url: '/tag/fighting' },
              { label: 'Shooter', url: '/tag/shooter' },
              { label: 'Sci-Fi', url: '/tag/sci-fi' },
              { label: 'Strategy', url: '/tag/strategy' },
              { label: 'RPG', url: '/tag/rpg' },
              { label: 'Sports', url: '/tag/sports' },
              { label: 'Fantasy', url: '/tag/fantasy' },
              { label: 'Puzzle', url: '/tag/puzzle' },
              { label: 'Horror', url: '/tag/horror' },
            ]}
          />
        </div>
      </>
    )}
  </div>
)

FilterMenu.defaultProps = {
  activeFilter: undefined,
}

FilterMenu.propTypes = {
  activeFilter: PropTypes.string,
}

export default FilterMenu
